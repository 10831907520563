export const pStudio = {
    account: {
        read: 's.account.read',
        write: 's.account.write'
    },

    announcement: {
        read: 's.announcement.read',
        write: 's.announcement.write',
        delete: 's.announcement.delete',
        setActive: 's.announcement.setActive',
        setActiveAny: 's.announcement.setActiveAny'
    },

    assessmentCenter: {
        create: 's.assessmentCenter.create',
        read: 's.assessmentCenter.read',
        update: 's.assessmentCenter.update',
        addQualificationTitle: 's.assessmentCenter.addQualificationTitle',
        delete: 's.assessmentCenter.delete'
    },

    assessor: {
        read: 's.assessor.read',
        readRole: 's.assessor.readRole',
        write: 's.assessor.write',
        resetPwd: 's.assessor.resetPwd',
        setActive: 's.assessor.setActive'
    },

    certificateValidity: {
        read: 's.certificateValidity.read',
        write: 's.certificateValidity.write'
    },

    examEvaluation: {
        read: 's.examEvaluation.read',
        write: 's.examEvaluation.write',
        delete: 's.examEvaluation.delete'
    },

    certificateTemplate: {
        read: 's.certificateTemplate.read',
        write: 's.certificateTemplate.write',
        delete: 's.certificateTemplate.delete'
    },

    examConfig: {
        read: 's.examConfig.read',
        write: 's.examConfig.write'
    },

    examDate: {
        read: 's.examDate.read',
        write: 's.examDate.write',
        delete: 's.examDate.delete'
    },

    unitCompetency: {
        read: 's.unitCompetency.read',
        write: 's.unitCompetency.write',
        delete: 's.unitCompetency.delete'
    },

    qualificationTitle: {
        read: 's.qualificationTitle.read',
        write: 's.qualificationTitle.write',
        delete: 's.qualificationTitle.delete'
    },

    quiz: {
        read: 'l.quiz.read',
        write: 'l.quiz.write',
        delete: 'l.quiz.delete',
        setActive: 'l.quiz.setActive',
        readSelfAssess: 'l.quiz.readSelfAssess',
        writeSelfAssess: 'l.quiz.writeSelfAssess',
        deleteSelfAssess: 'l.quiz.deleteSelfAssess',
        setActiveSelfAssess: 'l.quiz.setActiveSelfAssess'
    },

    payment: {
        read: 's.payment.read',
        write: 's.payment.write',
        delete: 's.payment.delete'
    },

    practiceExam: {
        read: 's.practiceExam.read',
        write: 's.practiceExam.write',
        delete: 's.practiceExam.delete'
    },

    occupation: {
        read: 's.certicateType.read',
        write: 's.certicateType.write',
        delete: 's.certicateType.delete'
    },

    sector: {
        read: 's.sector.read',
        write: 's.sector.write',
        delete: 's.sector.delete'
    },

    staff: {
        read: 's.staff.read',
        readRole: 's.staff.readRole',
        write: 's.staff.write',
        resetPwd: 's.staff.resetPwd',
        setActive: 's.staff.setActive'
    },
    student: {
        read: 's.student.read',
        write: 's.student.write',
        resetPwd: "s.student.resetPwd",
        createExam: 's.student.createExam'
    },
    verifyStudent: {
        read: 's.verifyStudent.read',
        write: 's.verifyStudent.write'
    },

    studentCertificate: {
        read: 's.studentCertificate.read',
        write: 's.studentCertificate.write',
        print: 's.studentCertificate.print'
    },

    studentCompetency: {
        read: 's.studentCompetency.read',
        write: 's.studentCompetency.write',
        print: 's.studentCompetency.print'
    },

    title: {
        read: 's.title.read',
        write: 's.title.write',
        delete: 's.title.delete'
    },

    logUser: {
        read: 's.logUser.read'
    },

    logAdmin: {
        read: 's.logAdmin.read'
    },

    logAssessmentCenter: {
        read: 's.logAssessmentCenter.read'
    },

    submitCertificate: {
        read: 's.submitCertificate.read',
        write: 's.submitCertificate.write'
    },

    report: {
        candidate: 's.report.candidate',
        staff: 's.report.staff',
        certificate: 's.report.certificate',
        payment: 's.report.payment'
    }
};
