<div class="app-content">
    <div class="app-title gap-8">
        <h1 class="title">{{title | translate}}</h1>
    </div>
</div>

<mat-dialog-content>
    <div class="creation-form" autocomplete="off">
        <form id="form" [formGroup]="form">
            <div class="input-group">
                <div class="grid gap-x-16">
                    <div>
                        <label class="required">{{'dialog.reason' | translate}}</label>
                        <mat-form-field appearance="outline">
                            <textarea matInput rows="3" formControlName="reason" [placeholder]="'dialog.reject_hint' | translate"></textarea>
                        </mat-form-field>
                    </div>
                </div>
            </div>
        </form>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-raised-button mat-dialog-close>
        <mat-icon>highlight_off</mat-icon>
        {{'button.cancel' | translate}}
    </button>
    <button form="form" mat-raised-button color="warn" type="submit" (click)="onSubmit()">
        <mat-icon>check_circle_outline</mat-icon>
        {{'button.reject' | translate}}
    </button>
</mat-dialog-actions>