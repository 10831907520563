import { Pipe, PipeTransform } from '@angular/core';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'staticFile'
})
export class StaticFilePipe implements PipeTransform {
  transform(value: string, type : 'streaming' | 'download' = 'download'): unknown {
    if (value && type === 'download') {
      return environment.file_static_url + value;
    }
    if (value && type === 'streaming') {
      return environment.file_streaming_url + value
  }
    return null;
  }
}
