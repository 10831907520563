<mat-dialog-content align="center">
  <div class="alert-icon-dialog">
    <img [src]="icon" alt="icon.svg" />
  </div>
  <h2 matDialogTitle>{{title | translate}}</h2>
  <span>{{message | translate}}</span>
</mat-dialog-content>
<mat-dialog-actions class="btn-action-dialog">
  <button *ngIf="button != 'back'" mat-raised-button matDialogClose="cancel">
    <mat-icon>highlight_off</mat-icon>
    {{button === 'close' ? 'Close': ('button.cancel' | translate)}}
  </button>
  <button *ngIf="button === 'delete'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>done</mat-icon>
    Okay
  </button>
  <button *ngIf="button === 'save'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>save</mat-icon>
    Save
  </button>
  <button *ngIf="button === 'confirm'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>check_circle_outline</mat-icon>
    {{'button.confirm' | translate}}
  </button>
  <button *ngIf="button === 'import'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon svgIcon="import-file"></mat-icon>
    Import
  </button>
  <button *ngIf="button === 'back'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>arrow_back</mat-icon>
    Back
  </button>
  <button *ngIf="button === 'check'" mat-raised-button matDialogClose="confirm" color="primary">
    <mat-icon>check_circle_outline</mat-icon>
    {{'button.check' | translate}}
  </button>
</mat-dialog-actions>