import { animate, state, style, transition, trigger } from '@angular/animations';
import { BreakpointObserver, BreakpointState, Breakpoints } from '@angular/cdk/layout';
import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatSidenav } from '@angular/material/sidenav';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs';
import { pStudio } from 'src/app/helpers/permission';
import { Unsubscribe } from 'src/app/helpers/unsubscribe';
import { LocalStorageEnum } from 'src/app/models/enums/local-storage.enum';
import { ChildItem, MenuItem } from 'src/app/models/menu-item';
import { AuthService } from 'src/app/services/auth.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { ProfileService } from 'src/app/services/profile.service';
import { RoleCheckerService } from 'src/app/services/role-checker.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-container',
  templateUrl: './container.component.html',
  styleUrls: ['./container.component.scss'],
  animations: [
    trigger('isVisibleChanged', [
      state('true', style({ opacity: 1 })),
      state('false', style({ opacity: 0 })),
      transition('1 => 0', animate('10ms')),
      transition('0 => 1', animate('900ms'))
    ])
  ]
})
export class ContainerComponent extends Unsubscribe implements OnInit {
  pStudio = pStudio;
  menu!: MenuItem[];
  isExpanded = true;
  showSubmenu = false;
  isShowing = false;
  showSubSubMenu = false;
  isFirefox: boolean;
  sidebarMode: any;
  mobileQuery!: boolean;
  smallScreen!: boolean;
  authUser!: object;
  isAuth!: boolean;
  account: any;
  activeAcademicYear!: string;
  authSubscribe: any;
  menuAdmin!: MenuItem[];
  langs = {
    'km': 'ខ្មែរ',
    'en': 'English'
  };
  browserLang: string;

  @ViewChild('sidenav') sidenav!: MatSidenav;

  constructor(
    public route: Router,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private profileService: ProfileService,
    private authService: AuthService,
    private router: Router,
    private userDataService: UserDataService,
    private breakpointObserver: BreakpointObserver,
    private localStorageService: LocalStorageService,
    private roleCheckerService: RoleCheckerService,
    private platform: Platform,
    private translate: TranslateService
  ) {
    super();
    this.isFirefox = platform.FIREFOX;
    // translate.addLangs(['en', 'km']);
    // translate.setDefaultLang('km');

    this.browserLang = localStorageService.get(LocalStorageEnum.language);//navigator.language;
    // translate.use(this.browserLang.match(/en|zh-CN|km/) ? this.browserLang : 'km'); // default language 'km'

    this.menuAdmin = [
      {
        title: 'menu.dashboard',
        route: '/home',
        icon: '',
        svgIcon: 'dashboard',
        child: [],
        permissions: []
      },
      {
        title: 'menu.announcement',
        route: '/announcement',
        icon: '',
        svgIcon: 'announcement',
        child: [],
        permissions: [pStudio.announcement.read]
      },
      {
        title: 'menu.assessment_center',
        route: '/assessment-center',
        icon: '',
        svgIcon: 'school',
        permissions: [pStudio.assessmentCenter.read],
        child: []
      },
      {
        title: 'menu.human_resource.title',
        route: '/human-resource',
        icon: '',
        svgIcon: 'human',
        permissions: [],
        child: [
          {
            title: 'menu.human_resource.staff',
            route: ['/human-resource/staff'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.staff.read]
          },
          {
            title: 'menu.human_resource.assessor',
            route: ['/human-resource/assessor'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.assessor.read]
          }
        ]
      },
      {
        title: 'menu.assessment_operation.title',
        route: '/assessment-operation',
        icon: '',
        svgIcon: 'processes',
        permissions: [],
        child: [
          // {
          //   title: 'Exam Date',
          //   route: ['/assessment-operation/exam-date-list'],
          //   icon: 'radio_button_unchecked',
          //   svgIcon: '',
          //   permissions: []
          // },
          {
            title: 'menu.assessment_operation.candidate',
            route: ['/assessment-operation/candidate-list'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.student.read]
          },
          {
            title: 'menu.assessment_operation.practice_exam',
            route: ['/assessment-operation/practice-exam'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.practiceExam.read]
          },
          {
            title: 'menu.assessment_operation.evaluation',
            route: ['/assessment-operation/evaluation-list'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.examEvaluation.read]
          },
          // {
          //   title: 'Certificate Print',
          //   route: ['/assessment-operation/certificate-print'],
          //   icon: 'radio_button_unchecked',
          //   svgIcon: '',
          //   permissions: []
          // },
          {
            title: 'menu.assessment_operation.issue_certificate',
            route: ['/assessment-operation/issue-certificate'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.studentCertificate.read]
          },
          {
            title: 'menu.assessment_operation.issue_competency',
            route: ['/assessment-operation/issue-competency'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.studentCompetency.read]
          },
          {
            title: 'menu.assessment_operation.existing_certificate',
            route: ['/assessment-operation/certificate-return'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.submitCertificate.read]
          }
        ]
      },
      {
        title: 'menu.payment',
        route: '/payment',
        icon: '',
        svgIcon: 'payment',
        child: [],
        permissions: [pStudio.payment.read]
      },
      {
        title: 'menu.report.title',
        route: '/report',
        icon: '',
        svgIcon: 'report',
        permissions: [],
        child: [
          {
            title: 'menu.report.candidate',
            route: ['/report/student'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.report.candidate]
          },
          {
            title: 'menu.report.staff',
            route: ['/report/staff'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.report.staff]
          },
          {
            title: 'menu.report.payment',
            route: ['/report/payment'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.report.payment]
          }
        ]
      },
      {
        title: 'menu.question_bank',
        route: '/question-bank',
        icon: '',
        svgIcon: 'quiz',
        permissions: [],
        child: [
          {
            title: 'question_bank.self_assessment',
            route: ['/question-bank/self-assessment'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.quiz.readSelfAssess]
          },
          {
            title: 'question_bank.qualification_test',
            route: ['/question-bank/qualification-test'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.quiz.read]
          }
        ]
      },
      {
        title: 'menu.setting.title',
        route: ['/setting'],
        icon: '',
        svgIcon: 'setting',
        child: [
          // {
          //   title: 'menu.setting.account_setting',
          //   route: ['/setting/account-setting'],
          //   icon: 'radio_button_unchecked',
          //   svgIcon: '',
          //   permissions: [pStudio.account.read]
          // },
          {
            title: 'menu.setting.staff_title',
            route: ['/setting/staff-title'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.title.read]
          },
          {
            title: 'menu.assessment_setup.certificate_template',
            route: ['/assessment/certificate-template'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.certificateTemplate.read]
          },
          {
            title: 'menu.assessment_setup.sector',
            route: ['/assessment/sector'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.sector.read]
          },
          {
            title: 'menu.assessment_setup.occupation',
            route: ['/assessment/occupation'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.occupation.read]
          },
          {
            title: 'menu.assessment_setup.unit_competency',
            route: ['/assessment/unit_competency'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.unitCompetency.read]
          },
          {
            title: 'menu.assessment_setup.qualification_title',
            route: ['/assessment/qualification-title'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.qualificationTitle.read]
          },
          {
            title: 'menu.assessment_setup.configuration',
            route: ['/assessment/configuration'],
            icon: 'radio_button_unchecked',
            svgIcon: '',
            permissions: [pStudio.examConfig.read]
          }
        ],
        permissions: []
      }
    ];
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/svg-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/side-nav-icon-set.svg')
    );
    this.matIconRegistry.addSvgIconSet(
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/icons/action-icons.svg')
    );
    this.authSubscribe = this.authService.authChange$.subscribe(isAuth => {
      this.isAuth = isAuth;
      if (this.isAuth) {
        this.profileService
          .getAccountInfo()
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(data => {
            this.account = data;
            localStorage.setItem('account', JSON.stringify(data));
            this.profileService.staffId = data._id;
            this.localStorageService.setArray(LocalStorageEnum.permissions, data.roles.permissions);
            this.userDataService.changeUserData(data);
            this.initSidenav();
          });
      }
    });
  }

  ngOnInit(): void {
    this.onSmallScreen();
    this.breakpointObserver.observe([Breakpoints.Large]).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.mobileQuery = false;
      }
    });
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.browserLang = language;
    this.localStorageService.set(LocalStorageEnum.language, language);
    // this.authService.authChange$.next(true);
  }

  initSidenav() {
    this.menu = [];
    for (let i = 0; i < this.menuAdmin.length; i++) {
      if (this.menuAdmin[i].child.length > 0) {
        let childs: ChildItem[] = [];
        for (let j = 0; j < this.menuAdmin[i]?.child.length; j++) {
          if (this.menuAdmin[i]?.child[j].permissions.length > 0) {
            if (this.checkPermission(this.menuAdmin[i]?.child[j].permissions)) {
              childs.push(this.menuAdmin[i].child[j]);
            }
          } else {
            childs.push(this.menuAdmin[i].child[j]);
          }
        }

        if (childs.length > 0) {
          let m: MenuItem = JSON.parse(JSON.stringify(this.menuAdmin[i]));
          m.child = JSON.parse(JSON.stringify(childs));
          this.menu.push(m);
        }
      } else {
        // check permission menu without children
        if (this.menuAdmin[i].permissions.length > 0) {
          if (this.checkPermission(this.menuAdmin[i].permissions)) {
            this.menu.push(this.menuAdmin[i]);
          }
        } else {
          this.menu.push(this.menuAdmin[i]);
        }
      }
    }
  }

  public checkPermission(arr: string[]): boolean {
    let perms: string[] = this.roleCheckerService.GetPermissions();

    if (perms == undefined || perms.length <= 0) {
      return false;
    }
    for (let i = 0; i < arr.length; i++) {
      if (perms.filter(e => e == arr[i]).length > 0) {
        return true;
      }
    }
    return false;
  }

  isChildActive(childs: MenuItem[] | ChildItem[]): boolean {
    for (let i = 0; i < childs.length; i++) {
      if (this.router.isActive(this.router.createUrlTree(childs[i].route), false)) {
        return true;
      }
    }
    return false;
  }

  onResize(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 959 && window.innerWidth <= 1280) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }
  onSmallScreen(): void {
    if (window.innerWidth <= 959) {
      this.mobileQuery = true;
      this.isExpanded = false;
    }
    if (window.innerWidth > 959 && window.innerWidth <= 1280) {
      this.isExpanded = false;
      this.mobileQuery = false;
    }
  }

  toggleSideNav(): void {
    if (this.mobileQuery) {
      this.sidenav.toggle();
    } else {
      this.isExpanded = !this.isExpanded;
    }
  }

  logout(): void {
    this.isAuth = false;
    this.authSubscribe.unsubscribe();
    this.authService.logout();
    this.router.navigateByUrl('/login');
    // this.authService.logoutFromAPI().subscribe({
    //   next: res => {
    //     this.isAuth = false;
    //     this.authSubscribe.unsubscribe();
    //     this.authService.logout();
    //     this.router.navigateByUrl('/login');
    //   },
    //   error: err => {
    //     console.error(err);
    //   }
    // });
  }
}
