export default class EnumConstant {
  public static readonly ACTIVE = 1;
  public static readonly INACTIVE = 0;
  public static readonly DISABLED = -2;
  public static readonly APPROVED = 1;
  public static readonly REQUESTED = 4;
  public static readonly PENDING = 5;
  public static readonly REJECTED = -4;
  public static readonly DELETE = -9;
  public static readonly CANCEL = 9;
  public static readonly EXPIRED = 8;

  public static readonly PUBLIC = 1;
  public static readonly DRAFT = 2;
  public static readonly DISABLE_OWN = -1;
  public static readonly REQUESTING = 3;
  public static readonly REJECT = -3;
  public static readonly UNPUBLISHED = -1;
  public static readonly PUBLISHED = 1;
  public static readonly VERIFIED = 1;

  public static readonly Attendance = {
    PRESENT: 1,
    ABSENT: 2,
    PERMISSION: 3,
    LATE: 4
  };

  public static readonly Gender = {
    MALE: 'male',
    FEMALE: 'female'
  };

  public static readonly Enrolment = {
    NA: 0,
    PASSED: 1,
    FAILED: -1,
    DELETE_NAME: -8
  };

  public static readonly ReadPermission = {
    PUBLIC: 1,
    PRIVATE: -1
  };

  public static readonly MaritalStatus = {
    SINGLE: 'single',
    MARRIED: 'married',
    DIVORCED: 'divorced'
  };

  public static readonly CertificateType = {
    QUALIFICATION: 1,
    COMPETENCY: 2
  };
}

export enum Role {
  ALL = 'all',
  ADMIN = 'admin',
  SCHOOL = 'school',
  TEACHER = 'teacher',
  STUDENT = 'student',
  FACULTY = 'faculty',
  PARENT = 'parent',
  OFFICER = 'officer',
  DEPARTMENT = 'department',
  VIEWER = 'viewer'
}

export enum RoleId {
  ADMIN = 1 | 11,
  SCHOOL = 2,
  TEACHER = 3,
  STUDENT = 4,
  FACULTY = 5,
  DEPARTMENT = 6,
  PARENT = 7,
  ASSESSOR = 12,
  CENTER = 13,
  VIEWER = 14,
  EDITOR = 15
}

export enum AcademicType {
  SHORT_COURSE = 'short_course',
  PROGRAM = 'program'
}

export enum Announcement {
  MINISTRY = 'ministry',
  SCHOOL = 'school',
  STUDENT = 'student',
  CLASS = 'class'
}

export enum ClassSubjectStatus {
  DEFAULT = 1,
  ADDED = 0
}

export enum WeekDay {
  Monday = 1,
  Tuesday = 2,
  Wednesday = 3,
  Thursday = 4,
  Friday = 5,
  Saturday = 6,
  Sunday = 7
}

export enum Attendance {
  PRESENT = 1,
  ABSENT = 2,
  PERMIT = 3,
  LATE = 4
}

export enum UserStatusEnum {
  active = 1,
  pending = 0,
  inactive = -1,
  disable = -2
}

export enum UnitTypeEnum {
  BASIC = 1,
  CORE = 2
}

export enum HistoryActionEnum {
  RESET_PASSWORD = 'reset password',
  CHANGE_PASSWORD = 'change password',
  LOGIN = 'login',
  CREATE = 'create',
  DELETE = 'delete',
  UPDATE = 'update'
}

export enum CurrencyEnum {
  USD = 'usd',
  RIEL = 'khr'
}

export enum SkillLevelEnum {
  ZERO = 0,
  MINNUS_ONE = -1,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5
}

export enum QuizType {
  QUALIFICATION = '1',
  'PRE-TEST' = '2'
}

export enum QuizTypeEnum {
  QUALIFICATION = 1,
  PRE_TEST = 2
}

export const SkillLevelLabel = {
  [SkillLevelEnum.ZERO]: 'វិញ្ញាបនបត្រសមត្ថភាព',
  [SkillLevelEnum.MINNUS_ONE]: 'លិខិតបញ្ជាក់ក្រេឌីត',
  [SkillLevelEnum.ONE]: 1,
  [SkillLevelEnum.TWO]: 2,
  [SkillLevelEnum.THREE]: 3,
  [SkillLevelEnum.FOUR]: 4,
  [SkillLevelEnum.FIVE]: 5
};

export enum ExamState {
  FAILED = -1,
  INCOMPLETE = 0,
  PASSED = 1,
  PRETEST = 2,
  PAYMENT = 3,
  QUALIFICATION_TEST = 4,
  PRACTICE = 5
}

export enum CandidateStatus {
  REJECTED = -3,
  PENDING = 3,
  VERIFIED = 1
}

export enum DocRequirement {
  ID_Card = 1,
  Birth_Certificate = 2
}

export enum QuestionType {
  SINGLE_CHOICE = 1,
  MATCHING = 2
}

export enum BaseKeyAddressEnum {
  BASE_PROVINCE = 'baseProvince',
  BASE_DISTRICT = 'baseDistrict',
  BASE_COMMUNE = 'baseCommune',
  BASE_VILLAGE = 'baseVillage',
  BASE_CURRENT_PROVINCE = 'baseProvince',
  BASE_CURRENT_DISTRICT = 'baseCurrentDistrict',
  BASE_CURRENT_COMMUNE = 'baseCurrentCommune',
  BASE_CURRENT_VILLAGE = 'baseCurrentVillage'
}
