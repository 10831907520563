import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { ContainerComponent } from './components/container/container.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: ContainerComponent,
    children: [
      {
        path: 'home',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/home/home.module').then(m => m.HomeModule)
      },
      {
        path: 'assessment-center',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/assessment-center/assessment-center.module').then(m => m.AssessmentCenterModule)
      },
      {
        path: 'assessment-operation',
        canActivate: [AuthGuard],
        loadChildren: () =>
          import('./routes/assessment-operation/assessment-operation.module').then(m => m.AssessmentOperationModule)
      },
      {
        path: 'human-resource',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/human-resource/human-resource.module').then(m => m.HumanResourceModule)
      },
      {
        path: 'announcement',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/announcement/announcement.module').then(m => m.AnnouncementModule)
      },
      {
        path: 'report',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'assessment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/assessment/assessment.module').then(m => m.AssessmentModule)
      },
      {
        path: 'setting',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/setting/setting.module').then(m => m.SettingModule)
      },
      {
        path: 'question-bank',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/question-bank/question-bank.module').then(m => m.QuestionBankModule)
      },
      {
        path: 'payment',
        canActivate: [AuthGuard],
        loadChildren: () => import('./routes/payment/payment.module').then(m => m.PaymentModule)
      }
    ]
  },
  {
    path: 'login',
    loadChildren: () => import('./routes/login/login.module').then(m => m.LoginModule)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./routes/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./routes/change-password/change-password.module').then(m => m.ChangePasswordModule)
  },
  {
    path: '**',
    component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
